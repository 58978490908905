<template>
    <div class="product-display">
        <img
                class="pic1"
                referrerpolicy="no-referrer"
                src="@/assets/product-display/banner.png"
        />
        <div class="content flex-col ali-center">
            <div class="commonTitle">产品中心</div>
            <!-- <div class="sTitle">PRODUCT CENTER</div> -->
            <div class="centerContent flex-row ">
                <div class="centerItem flex-col" style="margin-bottom: 3rem;" v-for="item in loopdata.records" :key="item.id" @click="fun(item)">

                    <!--                <img :src="'http://101.35.0.207:8888/'+item.coverImg" alt="" srcset="">   以前的-->
                    <img :src="'http://101.35.0.207:8888/'+item.coverImg" alt="" srcset="">
                    <img alt="" v-if="item.videoUrl != null && item.videoUrl != ''" srcset="../assets/icon_play01.png" @click="play(item)" class="play"/>
                    <div class="desc flex-col jus-center" v-if="loopdata.records.length> 0">
                        <div class="desTitle">{{ item.title }}</div>
                        <div class="desContent">{{ item.content }}</div>
                    </div>
                    <div class="desc flex-col jus-center bigText" v-else>{{ item.title }}</div>
                </div>

            </div>
            <el-pagination
                    background
                    layout="prev, pager, next"
                    prev-text="上一页"
                    next-text="下一页"
                    @current-change="getCurrentPage"
                    :page-size="4"
                    :total="loopdata.total">
            </el-pagination>
            <!-- <router-view></router-view> -->
        </div>


        <el-dialog
                class="dialog"
                :visible.sync="videoDialog"
                top="14vh"
                @open="openFun"
                width="62%"
                :before-close="videoClose"
                :append-to-body="true"
                :showClose="false">

            <div id="player">
                <video-player
                        ref="videoPlayer1"
                        class="video-player vjs-big-play-centered"
                        :playsinline="false"
                        :options="playerOptions"
                        @ready="onPlayerReady"
                        @play="onPlayerPlay($event)"
                        @pause="onPlayerPause($event)"
                        @ended="onPlayerEnd($event)"
                />
            </div>

        </el-dialog>
    </div>

</template>

<script>
    import { videoPlayer } from 'vue-video-player'
    import 'video.js/dist/video-js.css'
    import {getCpList} from "@/api/productCenter/productCenter.js";

    export default {
        name: "ProductDisplay",
        // eslint-disable-next-line vue/no-unused-components
        components: {videoPlayer},
        data() {
            return {
                currentPlayer: null,
                loopdata: {},
                listQuery: {
                    current: 1,
                    pageSize: 4,
                    listMode: true,
                    keyWord: '',
                    userfrom: null
                },
                // 视频弹窗
                videoDialog: false,
                videoUrl: '',
                playerOptions: {
                    playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                    autoplay: true, // 如果为true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: true, // 是否视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4", // 类型
                        src: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4' // url地址
                        // src: this.videoUrl  // url地址
                    }],
                    // poster: props.cover, // 封面地址
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: true, // 是否显示剩余时间功能
                        fullscreenToggle: true // 是否显示全屏按钮
                    }
                }
            }
        },
        created() {
            this.getList()
        },
        computed: {
            player() {
                return this.$refs.videoPlayer1 ? this.$refs.videoPlayer1.player : null;
            },
        },
        methods: {
            // 准备好了
            onPlayerReady(v) {
                console.log("准备好了")
                console.log(v)
                this.currentPlayer = v;
                this.onPlayerPlay(v)
            },
            // 播放了
            onPlayerPlay(v) {
                console.log("播放了")
                console.log(v)
            },
            // 暂停了
            onPlayerPause(v) {
                console.log("暂停了")
                console.log(v)
            },
            // 播放结束了
            onPlayerEnd(v) {
                console.log("播放结束了")
                console.log(v)
            },
            openFun(){
                if (this.currentPlayer){
                    //重置视频播放进度
                    this.currentPlayer.src(this.videoUrl)
                    //开始播放
                    this.currentPlayer.play();
                }
            },
            videoClose(){
                this.videoDialog = false
                console.log("停止")
                console.log(this.player)
                if (this.currentPlayer){
                    this.currentPlayer.pause();
                }
            },
            fun(item) {
                console.log("item", item)
                //插入用户操作数据
                this.userfrom = window.sessionStorage.getItem("from")
                this.$mybus.emit('userBehavior', {from: this.userfrom, moduleLevel1: 3, moduleLevel2: 1, moduleLevel3: item.id})
            },
            play(item) {
                console.log("播放视频", item)
                // this.videoUrl = "http://101.35.0.207:8888/" + item.videoUrl
                this.videoDialog = true
                this.videoUrl = "http://101.35.0.207:8888/" + item.videoUrl
                this.playerOptions.sources[0].src = "http://101.35.0.207:8888/" + item.videoUrl
            },
            getCurrentPage(e) {
                this.listQuery.current = e
                this.getList()
            },
            getList() {
                this.listQuery.subsystemIdentificationCode='1E1FF846F78548E6AC29076215F10CDA'
                getCpList(this.listQuery).then((response) => {
                    this.loopdata = response.data
                })
            },
        },
        mounted() {
            window.scrollTo(0, 0);
            this.userfrom = window.sessionStorage.getItem("from")
            this.$mybus.emit('userBehavior', {from: this.userfrom, moduleLevel1: 3, moduleLevel2: 1})
        }
    };
</script>
<style scoped>

</style>
<style>
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #025D91;
        color: #FFF;
    }

    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .btn-next {
        padding-left: 15px;
        padding-right: 15px;
    }
</style>

<style lang="stylus" scoped>
    >>> .video-js .vjs-control-bar{
        bottom -6%
    }
    >>> .video-js .vjs-play-progress:before {
        top -4px
    }
    .desContent {
        text-align left
        height 75px
    }
    .commonTitle {
        margin-bottom 60px
    }

    .product-display {
        padding-top: 80px;
    }

    .pic1 {
        width: 100%;
        height: 600px;
        display: block;
    }

    .content {
        box-sizing border-box
        // height 1076px
        padding-top 70px
        padding-bottom 150px

        .title {
            font-size: 26px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 37px;
            margin-bottom 10px
        }

        .sTitle {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 28px;
            margin-bottom 40px
        }
    }

    .img1 {
        width 1200px
        height 610px
        display block
        margin-bottom 80px
    }

    .dialog {
        >>> .el-dialog__header {
            background-color black
            padding: 0 0 0;
        }

        >>> .el-dialog__body {
            background-color black
            padding: 50px 0 50px;
            /*padding: 0px 0 0px;*/
        }
    }

    .centerContent {
        width 1220px
        height 990px
        flex-wrap wrap
        margin-bottom 110px

        .centerItem {
            width 590px
            // height 341px
            height 480px
            margin-right 20px
            transition .4s

            //播放按钮定位用
            position relative

            .play {
                position absolute
                left 43%
                top 30%
                cursor: pointer;
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 1px solid #A9A9A9;
            }


            &:hover {
                box-shadow 0px 6px 6px 3px rgba(0, 0, 0, .1)
                transform translateY(-2px)
            }

            img {
                width 100%
                height 360px
                display block
            }

            .bigText {
                font-size: 20px !important;
                line-height: 28px !important;
            }

            .desc {
                flex 1
                padding 0 20px
                background #025D91
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 25px;
                display: flex;
                flex-direction: column;
            }

            .desTitle {
                font-size: 16px;
                line-height: 60px;

                flex: 1;
            }
        }
    }
</style>
