import request from '@/utils/request'
import CONSTANT from '../../config/global.js'

/**
 * 查询官网产品中心数据
 * object{分页对象，相关属性current，pageSize，listMode}
 */
export function getCpList(data) {
  return request({
    url: CONSTANT.TwoHttp + '/external/productCenter/getList',
    method: 'post',
	data
  })
}


